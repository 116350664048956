import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';
import { Enums } from '../../_shared/enums/enums';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'website',
      category: 'website',
      defaultRoute: '/website/website-overview',
      displayName: 'Website',
      default: true,
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'website-overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        }]
    },
    {
        id: 'digAd',
        category: 'digAd',
        default: true,
        defaultRoute: '/digAd/dashboard',
        displayName: 'Digital Advertising',
        displayInNav: true,
        iconClass: 'fa fa-desktop',
        roles: undefined,
        children: [
            {
                parent: '/digAd',
                route: 'dashboard',
                displayName: 'Digital Advertising Overview',
                pdfEnabled: false,
                iconClass: 'fa fa-users',
                breadcrumbHelpEnabled: true,
                roles: undefined
            }
        ]
    },
    {
      id: 'provider-tools',
      category: 'chat',
      defaultRoute: '/provider-tools/chat-overview',
      displayName: 'Chat',
      displayInNav: true,
      iconClass: 'fa fa-comment',
      roles: undefined,
      children: [
        {
          parent: '/provider-tools',
          route: 'chat-overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
      ]
    },
    {
      id: 'provider-tools',
      category: 'trade-in',
      default: false,
      defaultRoute: '/provider-tools/trade-in-overview',
      displayName: 'Trade In',
      displayInNav: true,
      iconClass: 'fa fa-retweet',
      roles: undefined,
      children: [
        {
          parent: '/provider-tools',
          route: 'trade-in-overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-exchange',
          roles: undefined
        },
      ]
    },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: false,
      defaultRoute: '/provider-tools/digital-retailing',
      displayName: 'Digital Retailing',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: undefined,
        children: [
            {
                parent: '/provider-tools',
                route: 'digital-retailing-overview',
                displayName: 'Digital Retailing',
                pdfEnabled: false,
                breadcrumbHelpEnabled: true,
                iconClass: 'fa fa-users',
                roles: ['System Administrator', 'System Analyst', 'Corporate']
            },
        ]
      },
      {
          id: 'program-overview',
          category: 'program-overview',
          default: true,
          defaultRoute: '/program-overview/program-overview',
          displayName: 'Program Overview',
          displayInNav: true,
          iconClass: 'fa fa-server',
          roles: ['System Administrator', 'System Analyst', 'Corporate'],
          children: [
              {
                  id: "program-overview/program-overview",
                  parent: '/program-overview',
                  route: 'program-overview',
                  displayName: 'Program Overview',
                  pdfEnabled: false,
                  breadcrumbHelpEnabled: true,
                  iconClass: 'fa fa-server',
                  roles: ['System Administrator', 'System Analyst', 'Corporate']
              }]
      },
    {
      id: 'resources',
      category: 'resources',
      defaultRoute: '/resources/resources',
      displayName: 'Marketing Resources',
      displayInNav: true,
      iconClass: 'fa fa-wrench',
      roles: undefined,
      disableForDealerGroups: true,
      children: [
        {
          parent: '/resources',
          route: 'resources',
          displayName: 'Marketing Resources',
          iconClass: 'fa fa-wrench',
          roles: undefined,
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', 'System Analyst'],
      children: [
        {
          parent: '/management',
          route: 'provider',
          displayName: 'Providers',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst", "Corporate", "Dealer"],
          children:
          [
            {
              id: 'provider/create',
              parent: 'provider',
              route: 'create',
              displayName: 'Create Provider',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'provider/update',
              parent: 'provider',
              route: 'update/:id',
              displayName: 'Edit Provider',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            }
          ]
        },
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst']
        },
        {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create/:id',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update/:id',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create/:id',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create/project/:queryConfigId',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update/:id',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            }
          ]
        }, {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', 'System Analyst'],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create/:id',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update/:id',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/test',
              parent: 'query-configuration',
              route: 'test/:id',
              displayName: 'Test Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', 'System Analyst']
          },
          {
              parent: '/management',
              route: 'settings',
              displayName: 'Settings',
              iconClass: 'fa fa-code',
              roles: ['System Administrator']
          }
      ]
    }
  ];
}
