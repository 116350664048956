import { Injectable } from '@angular/core';
import { RoutingConfig } from '../../../../_config/routing/routing.config';
import { IRoutingConfig } from '../../../models/models-index';
import { AuthenticationService, RoleConfigService } from '../../services-index';
import * as Models from '../../../models/models-index';
const clone = require('rfdc/default');

@Injectable()
export class RoutingConfigService {

    routingConfig: IRoutingConfig = new RoutingConfig;

    // Routes available to the user in the nav
    get userNavRouteConfigs(): Models.IRouteConfig[] {
      return this.computeRoutes();
    }
    // All routes available to the user
    get allUserRouteConfigs(): Models.IRouteConfig[] {
      return this.computeRoutes(false);
  }
    // Default route for the user
    get defaultRoute(): string {
        return this.getDefaultRoute();
    }

    // Paths for all routes available to the user including those not in the nav
    get userRouteUrls(): string[] {
        let routeUrls: string[] = [];

        const getChildRoutes = (parentPath: string, children: Models.IRouteDetails[]) => {
            children.forEach(child => {
                const childPath = `${parentPath}/${child.route}`;
                routeUrls.push(childPath);

                // If the child has its own children, recursively get their routes
                if ((child?.children || []).length > 0) {
                    getChildRoutes(childPath, child.children);
                }
            });
        };
        this.routingConfig.routes.forEach(route => {
            const parent = `/${route.id}`;

            if ((route.children || []).length === 0) {
                routeUrls.push(parent);
            }
            else {
                getChildRoutes(parent, route.children);
            }
        });
        // console.log(routeUrls);

        return routeUrls;
    }

    constructor(private readonly roleConfigService: RoleConfigService,
        private readonly authenticationService: AuthenticationService) {

    }

    private getDefaultRoute(): string {
        const defaultRoute = this.userNavRouteConfigs.find(r => r.default === true);

        // If no particular route is set to default, return the first route in the list of routes available in the nav to the user
        return defaultRoute ? defaultRoute.defaultRoute : this.userNavRouteConfigs[0].defaultRoute;
    }

    computeRoutes(forNavBar: boolean = true): Models.IRouteConfig[] {
        const routes: Models.IRouteConfig[] = [];
        const user = this.authenticationService.getUser();

        if (user) {
            this.routingConfig.routes.forEach(route => {
                // filter out routes that are hidden or the user does not have access to
                if ((forNavBar && route.displayInNav === false) || !this.userHasAccessToRoute(user.role, route)) {
                    return;
                }

                // Recursively filter children based on user access
                const filteredChildren = this.filterChildrenRecursively(route.children, user.role);

                // construct the route and set the children & defaultRoutes according to the user's role
                let routeConfig: Models.IRouteConfig = {
                    ...route,
                    children: filteredChildren,
                    defaultRoute: filteredChildren.length === 0 ? route.defaultRoute : `${filteredChildren[0].parent}/${filteredChildren[0].route}`
                };
                routes.push(routeConfig);
            });
        }

        return routes;
    }

    private filterChildrenRecursively(
        children: Models.IRouteDetails[],
        userRole: string
    ): Models.IRouteDetails[] {
        if (!children || children.length === 0) {
            return [];
        }

        return children
            .filter(child => this.userHasAccessToChildRoute(userRole, child))
            .map(child => ({
                ...child,
                children: this.filterChildrenRecursively(child.children, userRole) // Recursively filter children of children
            }));
    }

    public userHasAccessToRoute(userRole: string, routeData: Models.IRouteConfig): boolean {
        const routeRoles = routeData.roles || [];

        if (!!routeData.disableForDealerGroups && this.roleConfigService.isDealerGroupRole(userRole)) {
            return false;
        }
        else {
            return (routeRoles).length === 0 ? true : routeRoles.indexOf(userRole) != -1;
        }
    }

    public userHasAccessToChildRoute(userRole: string, routeData: Models.IRouteDetails): boolean {
        const routeRoles = routeData.roles || [];

        return routeRoles.length === 0 ? true : routeRoles.indexOf(userRole) != -1;
    }
}
