export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Subaru',
  hostName: 'https://analytics-staging.subarucertifieddigital.com',
  environmentName: 'staging',
  appName: 'Subaru Analytics',
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#000', '#09f', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  applicationInsights: {
    instrumentationKey: '8666835f-d223-422a-b04b-89a2cc440f12'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  baseApiUri: 'https://subaru-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://subaru-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://subaru-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/subaru/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: [
    'WebsiteOverview',
    'DigitalAdvertisingDashboard',
    'ChatOverview',
    'DigitalRetailingOverview',
    'ProgramOverview']
};
